<template>
    <el-container>
        <el-aside width="200px" style="background-color:rgb(225 225 225);">
            <el-menu style="height:100%;" :uniqueOpened="true" class="el-menu-vertical-demo" router
                background-color="#545c64" text-color="#fff" active-text-color="#ffd04b">
                <el-menu-item :index="'/pages/mg/' + appkey +'/owner'"><i class="el-icon-user"></i><template
                        #title>应用管理员</template> </el-menu-item>
                <el-menu-item :index="'/pages/mg/' + appkey +'/roles'"><i class="el-icon-takeaway-box"></i><template
                        #title>角色管理</template> </el-menu-item>
                <el-menu-item :index="'/pages/mg/' + appkey +'/resource'"><i class="el-icon-folder-opened"></i><template
                        #title>资源管理</template> </el-menu-item>
            </el-menu>
        </el-aside>
        <el-main style="background-color:rgb(245,245,245)">
            <router-view />
        </el-main>
    </el-container>
</template>
<script>
    import { getPathVariableAppKey } from '@/utils/tools'
    export default {
        setup() {
            const appkey = getPathVariableAppKey()
            return {
                appkey
            }
        }
    }
</script>